import React, { useState } from "react";
import Input from "antd/es/input/Input";
import message from "antd/es/message";
import { useConnection } from "@solana/wallet-adapter-react";
import { HyperButton } from "../../../components/buttons/HyperButton";
import {  loadTokenDetailsBE } from "../../../utils/helpers";
import { getSolPrice, getMoonshotPool } from "../utils/apiCalls";
import volumebotstyle from '../style/moonshot.module.less';
import { getMode } from "../utils/helpers";

const LoadToken = ({ setTokenDetails, setSolPrice }) => {
    const [token, setToken] = useState('');
    const [loadingTokenDetails, setLoadingTokenDetails] = useState(false);
    const { connection } = useConnection();

    async function loadTokenDetails(address) {
        try {
            setLoadingTokenDetails(true);
            setTokenDetails(null);
            const [[result = null], poolStatus, solPrice] = await Promise.all([
                loadTokenDetailsBE({
                    address,
                    mode: getMode(connection),
                }),
                getMoonshotPool({ mintAddress: address }),
                getSolPrice()
            ]);

            if (result?.decimals) {
                setTokenDetails({ ...result, poolStatus });
                console.log("solPrice", solPrice)
                setSolPrice(solPrice)
                return;
            }
            throw new Error('Invalid token');
        } catch (err) {
            setTokenDetails(null);
            return message.error(err?.message || 'Invalid Token');
        } finally {
            setLoadingTokenDetails(false);
        }
    }


    return (
        <>
            <h3>Token address</h3>
            <div className='flex items-center gap-x-6 w-full md:flex-col md:gap-y-[1rem]'>
                <Input
                    className={`h-[42px] ${volumebotstyle.mintAddress}`}
                    placeholder='Token Mint address'
                    addonBefore='Mint Address'
                    value={token}
                    onChange={e => {
                        setToken(e.target.value);
                    }}
                />

                <HyperButton
                    btnSize='medium-btn'
                    text={loadingTokenDetails ? 'Loading' : 'Load'}
                    onClick={() => loadTokenDetails(token)}
                    disabled={loadingTokenDetails || !token.trim()}
                    loading={loadingTokenDetails}
                    className='md:w-[70%]'
                />
            </div>
        </>
    );
};

export default LoadToken;