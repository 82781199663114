import React, { useMemo, useRef, useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import message from 'antd/es/message';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';

import { TokenInfo } from '../components/TokenInfo';
import LoadToken from '../components/LoadToken';
import MoonBotForm from '../components/MoonBotForm';
import { HyperButton } from '../../../components/buttons/HyperButton';
import {
  BASE_PDA,
  BONDING_CHARGE,
  FACTOR,
  HYPERPAD_CHARGE,
  JITO_TIP_1X,
  PUMPFUN_CHARGE,
  TX_AMT_L,
} from '../../../envs/vars';
import { BotTransaction } from '../utils/BotTransactions';
import { SolUtils } from '../../../solana/SolUtils';
import { useCreateWallet, useInitCompaign } from '../../../utils/networkCalls';
import { NotifyContext } from '../../../context/Notify';
import { MoonBotInfo } from '../components/MoonBotInfo';
import { BOT_MIN_RATE } from '../utils/helpers';
import {
  MOONSHOT_BUMP_TX_AMOUNTS_TABS,
  VOLUME_TX_AMOUNT_TABS,
} from '../../../constants';

const Create = () => {
  const [mode, setMode] = useState('BUMP');

  const [tokenDetails, setTokenDetails] = useState(null);
  const [solPrice, setSolPrice] = useState(0);
  const [notifyApi] = useContext(NotifyContext);
  const notifyRef = useRef(null);

  const { connection } = useConnection();
  const wallet = useWallet();
  const [formData, setFormData] = useState({
    amt: MOONSHOT_BUMP_TX_AMOUNTS_TABS[0].value,
    count: 1,
    rate: 25,
    tip: JITO_TIP_1X,
  });

  const navigate = useNavigate();

  const { mutate: createWallet } = useCreateWallet({
    onSuccess: data => {
      const { pubKey, campaignId } = data.data.data[0] || {};
      createTxn({ pubKey, campaignId });
    },
    onError: () => {
      message.error('Internal Server Error, Please contact team!!');
    },
  });

  const { mutate: initCompaign } = useInitCompaign({
    onSuccess: () => {
      let campaignId = notifyRef.current;
      notifyRef.current &&
        notifyApi &&
        notifyApi.open({
          key: notifyRef.current,
          placement: 'bottomRight',
          message: 'Request Confirmed',
          description: (
            <div>
              <p>
                Please go to Manage Section to check bot transaction details!!
              </p>
              <a href={`/moonshot-bot/manage/${campaignId}`}>BOT DETAILS</a>
            </div>
          ),
          duration: 10,
        });
      notifyRef.current = null;
      setTimeout(() => {
        navigate(`/moonshot-bot/manage/${campaignId}`);
      }, 10 * 1000);
    },
    onError: err => {
      message.error(`Unable to start bot, ${err?.message || ''}`);
      notifyRef.current &&
        notifyApi &&
        notifyApi.open({
          key: notifyRef.current,
          placement: 'bottomRight',
          message: 'Request Failed',
          description: (
            <div>
              <p>
                We are unable to process your request, Please contact team !!
              </p>
            </div>
          ),
          duration: 10,
        });
      notifyRef.current = null;
    },
  });

  // For solAmt
  const [solVolAmt] = useMemo(() => {
    const { count, rate, amt } = formData;

    // Convert necessary fields to numbers
    const countNum = +count;
    const rateNum = +rate;
    const amtNum = +amt;
    const basePdaNum = +BASE_PDA;
    const hyperpadChargeNum = +HYPERPAD_CHARGE;

    // Break down complex calculations for clarity
    const baseAmount = 2 * basePdaNum + amtNum;
    const countAmount =
      countNum *
      ((1 + amtNum) * 0.004 + hyperpadChargeNum * (1 + rateNum / 60));

    // Calculate the total amount and fix the precision to 5 decimal places
    const amount = (baseAmount + countAmount).toFixed(5);
    console.log('amount', amount);

    // Return a number, not a string
    return [parseFloat(amount)];
  }, [formData]);

  // For solVolAmt
  const [solAmt] = useMemo(() => {
    const { count = 20, rate = 10, amt } = formData;

    // Convert necessary fields to numbers
    const countNum = +count;
    const rateNum = +rate;
    const amtNum = +amt;
    const basePdaNum = +BASE_PDA;
    const hyperpadChargeNum = +HYPERPAD_CHARGE;

    // Break down complex calculations for clarity
    const baseAmount = 2 * basePdaNum + amtNum;
    const countAmount =
      countNum *
      (amtNum * 0.01 +
        parseFloat((hyperpadChargeNum * (1 + rateNum / 60)).toFixed(5)));

    // Calculate the total amount
    const amount = baseAmount + countAmount;

    // Return the amount as a number (not applying toFixed again)
    return [amount];
  }, [formData]);

  const createTxn = async ({ pubKey, campaignId }) => {
    const txn = await BotTransaction.createTxn({
      connection,
      wallet,
      solAmt: mode === 'BUMP' ? solAmt : solVolAmt,
      targetAddress: pubKey,
      mintAddress: tokenDetails?.mint,
    });

    const signedTxn = await SolUtils.getSignedTransaction(
      connection,
      txn,
      wallet,
      wallet.publicKey,
    );

    const base64Transaction = Buffer.from(signedTxn.serialize()).toString(
      'base64',
    );

    if (base64Transaction) {
      notifyRef.current = campaignId;
      notifyApi &&
        notifyApi.open({
          key: notifyRef.current,
          placement: 'bottomRight',
          message: 'Processing Request',
          description:
            'We are processing your request, Please wait while we confirm your request!!',
          duration: 60,
        });
      initCompaign({
        campaignId,
        createdBy: wallet.publicKey.toString(),
        txnPerMin: formData.rate || 10,
        jitoEnabled: false,
        jitoRatePerTxn: formData.tip * LAMPORTS_PER_SOL,
        maxTxnLimit: formData.count || 20,
        exchangeType: 'MoonShot',
        tokenMintAddress: tokenDetails?.mint,
        symbol: tokenDetails?.metadata?.data?.symbol || '-',
        tradeAccountKey: pubKey,
        signedTxn: base64Transaction,
        perTxnAmt: formData.amt * LAMPORTS_PER_SOL,
        tokenName: tokenDetails?.metadata?.data?.name || '-',
        poolId: tokenDetails?.poolStatus?.pairAddress || '-',
      });
    }
  };

  return (
    <div className='flex gap-x-[2rem] flex-row md:flex-col md:gap-y-[2rem]'>
      <div className='flex flex-col w-[70%] md:w-[100%] p-6 gap-y-4 border bg-[var(--main-background-color)] border-[var(--main-border-color)] rounded-[5px]'>
        <LoadToken
          setTokenDetails={setTokenDetails}
          setSolPrice={setSolPrice}
        />
        {tokenDetails ? <TokenInfo tokenDetails={tokenDetails} /> : null}
        <MoonBotForm
          solPrice={solPrice}
          formData={formData}
          setFormData={setFormData}
          mode={mode}
          setMode={setMode}
        />
        <HyperButton
          className='w-fit mx-auto mt-8'
          text={`Pay and Start (~${(mode === 'BUMP' ? +solAmt : +solVolAmt).toFixed(4)})`}
          onClick={() => createWallet({ platform: 'MoonShot' })}
          disabled={!tokenDetails || !tokenDetails?.poolStatus?.pairAddress}
          loading={false}
        />
      </div>
      <div className='w-[30%] md:w-[100%] p-6 gap-y-4 border bg-[var(--main-background-color)] border-[var(--main-border-color)] rounded-[5px]'>
        <MoonBotInfo />
      </div>
    </div>
  );
};

export default Create;
