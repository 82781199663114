import React, { useMemo, useRef, useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import message from 'antd/es/message';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';

import { TokenInfo } from '../components/TokenInfo';
import LoadToken from '../components/LoadToken';
import VolumeBotForm from '../components/VolueBotForm';
import { HyperButton } from '../../../components/buttons/HyperButton';
import {
  BASE_PDA,
  BONDING_CHARGE,
  FACTOR,
  HYPERPAD_CHARGE,
  JITO_TIP_1X,
  PUMPFUN_CHARGE,
  TX_AMT_L,
} from '../../../envs/vars';
import { BotTransaction } from '../utils/BotTransactions';
import { SolUtils } from '../../../solana/SolUtils';
import { useCreateWallet, useInitCompaign } from '../../../utils/networkCalls';
import { NotifyContext } from '../../../context/Notify';
import { VolumeBotInfo } from '../components/VolumeBotInfo';
import { BOT_MIN_RATE } from '../utils/helpers';
import { VOLUME_TX_AMOUNT_TABS } from '../../../constants';
import { Tutorial } from '../components/VolumeTutorial';

const Create = () => {
  const [tokenDetails, setTokenDetails] = useState(null);
  const [solPrice, setSolPrice] = useState(0);
  const [notifyApi] = useContext(NotifyContext);
  const notifyRef = useRef(null);

  const { connection } = useConnection();
  const wallet = useWallet();
  const [formData, setFormData] = useState({
    amt: VOLUME_TX_AMOUNT_TABS[0].value,
    count: 1,
    rate: 25,
    tip: JITO_TIP_1X,
  });

  const navigate = useNavigate();

  const { mutate: createWallet } = useCreateWallet({
    onSuccess: data => {
      const { pubKey, campaignId } = data.data.data[0] || {};
      createTxn({ pubKey, campaignId });
    },
    onError: () => {
      message.error('Internal Server Error, Please contact team!!');
    },
  });

  const { mutate: initCompaign } = useInitCompaign({
    onSuccess: () => {
      let campaignId = notifyRef.current;
      notifyRef.current &&
        notifyApi &&
        notifyApi.open({
          key: notifyRef.current,
          placement: 'bottomRight',
          message: 'Request Confirmed',
          description: (
            <div>
              <p>
                Please go to Manage Section to check bot transaction details!!
              </p>
              <a href={`/solana-volume-bot/manage/${campaignId}`}>
                BOT DETAILS
              </a>
            </div>
          ),
          duration: 10,
        });
      notifyRef.current = null;
      setTimeout(() => {
        navigate(`/solana-volume-bot/manage/${campaignId}`);
      }, 10 * 1000);
    },
    onError: err => {
      message.error(`Unable to start bot, ${err?.message || ''}`);
      notifyRef.current &&
        notifyApi &&
        notifyApi.open({
          key: notifyRef.current,
          placement: 'bottomRight',
          message: 'Request Failed',
          description: (
            <div>
              <p>
                We are unable to process your request, Please contact team !!
              </p>
            </div>
          ),
          duration: 10,
        });
      notifyRef.current = null;
    },
  });

  const [solAmt, platformFee] = useMemo(() => {
    const { count = 20, rate = 10, tip, amt } = formData;

    // Ensure numbers are used for calculations
    const countNum = +count;
    const rateNum = +rate;
    const amtNum = +amt;

    // Calculate platform fee
    let ptFee = Math.max(
      countNum * HYPERPAD_CHARGE * (1 + rateNum / 60),
      0,
    ).toFixed(5);

    // Calculate amount
    let amount = (
      3 * BASE_PDA +
      amtNum +
      countNum * (0.002 * (1 + amtNum))
    ).toFixed(5);

    // Convert the results back to numbers
    return [parseFloat(amount), parseFloat(ptFee)];
  }, [formData]);

  const createTxn = async ({ pubKey, campaignId }) => {
    const txn = await BotTransaction.createTxn({
      connection,
      wallet,
      solAmt,
      platformFee,
      targetAddress: pubKey,
      mintAddress: tokenDetails?.mint,
    });

    const signedTxn = await SolUtils.getSignedTransaction(
      connection,
      txn,
      wallet,
      wallet.publicKey,
    );

    const base64Transaction = Buffer.from(signedTxn.serialize()).toString(
      'base64',
    );

    if (base64Transaction) {
      notifyRef.current = campaignId;
      notifyApi &&
        notifyApi.open({
          key: notifyRef.current,
          placement: 'bottomRight',
          message: 'Processing Request',
          description:
            'We are processing your request, Please wait while we confirm your request!!',
          duration: 60,
        });
      initCompaign({
        campaignId,
        createdBy: wallet.publicKey.toString(),
        txnPerMin: formData.rate || 10,
        jitoEnabled: false,
        jitoRatePerTxn: formData.tip * LAMPORTS_PER_SOL,
        maxTxnLimit: formData.count || 20,
        exchangeType: 'Radiyum',
        tokenMintAddress: tokenDetails?.mint,
        symbol: tokenDetails?.fileData?.symbol,
        tradeAccountKey: pubKey,
        signedTxn: base64Transaction,
        perTxnAmt: formData.amt * LAMPORTS_PER_SOL,
        tokenName: tokenDetails?.metadata?.data?.name || '-',
        poolId: tokenDetails?.poolStatus?.id || '-',
      });
    }
  };

  return (
    <div className='flex gap-x-[2rem] flex-row md:flex-col md:gap-y-[2rem]'>
      <div className='flex gap-y-[2rem] flex-col w-[70%] md:w-[100%]'>
        <div className='flex flex-col w-[100%] p-6 gap-y-4 border bg-[var(--main-background-color)] border-[var(--main-border-color)] rounded-[5px]'>
          <LoadToken
            setTokenDetails={setTokenDetails}
            setSolPrice={setSolPrice}
          />
          {tokenDetails ? <TokenInfo tokenDetails={tokenDetails} /> : null}
          <VolumeBotForm
            solPrice={solPrice}
            formData={formData}
            setFormData={setFormData}
          />
          <HyperButton
            className='w-fit mx-auto mt-8'
            text={`Pay and Start (~${(+solAmt + +platformFee).toFixed(4)})`}
            onClick={() => createWallet({ platform: 'Radiyum' })}
            disabled={!tokenDetails || !tokenDetails?.poolStatus?.id}
            loading={false}
          />
        </div>

        <div className='p-6 border bg-[var(--main-background-color)] border-[var(--main-border-color)] rounded-[5px]'>
          <Tutorial />
        </div>
      </div>

      <div className='w-[30%] md:w-[100%] p-6 gap-y-4 border bg-[var(--main-background-color)] border-[var(--main-border-color)] rounded-[5px]'>
        <VolumeBotInfo />
      </div>
    </div>
  );
};

export default Create;
