import React, { useState, useEffect } from "react";
import axios from 'axios';
import Card from 'antd/es/card';

export const TokenInfo = ({ tokenDetails, loading = false, ...props }) => {
    return (
        <Card size='small' title={`Token Details`} loading={loading} {...props}>
            <div className='flex items-start gap-x-8'>
                <img className='mt-1' width={70} height={70} src={tokenDetails?.poolStatus?.profile?.icon} alt='' />
                <div className='flex gap-x-4 flex-1'>
                    <div className='flex flex-col gap-y-2 w-[80%]'>
                        <p className='m-0'>
                            <span>Token Name:&nbsp;&nbsp;</span>
                            <span className='font-bold'>
                                {tokenDetails?.metadata?.data?.name || '-'}
                            </span>
                        </p>
                        <p className="m-0">
                            <span>Token Ticker:&nbsp;&nbsp;</span>
                            <span className='font-bold'>{tokenDetails?.metadata?.data?.symbol || '-'}</span>
                        </p>
                        <p className="m-0">
                            <span>Token Description:&nbsp;&nbsp;</span>
                            <span className='font-bold'>{tokenDetails?.poolStatus?.profile?.decription || '-'}</span>
                        </p>
                         <p>
                            <span>Liquidity Pool:&nbsp;&nbsp;</span>
                            <span className='font-bold'>{tokenDetails?.poolStatus?.pairAddress || '-'}</span>
                        </p>
                    </div>

                </div>
            </div>
        </Card>
    );
};